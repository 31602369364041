import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import {Divider} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNight from '@mui/icons-material/ModeNight';
import { switchMode } from '../components/Theme/modeSlice';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import apiClient from '../request/http-common';
import { setAuthStatus } from '../request/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useMutation } from "react-query";

const Search = styled('div')(({ theme }) => ({
  
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));


export default function Header() {
  const appMode = useSelector((state) => state.mode.mode);
  const notifications = useSelector((state) => state.notifications.notifications);
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const toggleSidebar = () => {
    const event = new Event("toggleSideBar");
    window.dispatchEvent(event);
  }


  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const { isLoading: isLoggingOut, mutate: postLogout } = useMutation(
    async () => {
      return await apiClient.post(`/api/logout`);
    },
    {
      onSuccess: (res) => {
          if (res.data.message === 'successful') {
            dispatch(setAuthStatus('unauthenticated'));
            localStorage.removeItem('user');
            localStorage.removeItem('fundings');
            localStorage.setItem('funding-balance', 0);
            localStorage.setItem('subscriptionBalance', 0);
            localStorage.removeItem('subscriptions');
            localStorage.removeItem('notifications');
            localStorage.removeItem('auth-token')
            
            const event = new Event('userIsLoggedOut');
            window.dispatchEvent(event);
            navigate('/login');

          }            
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );

  return (
    <Box  sx={{ flexGrow: 1, position: 'fixed', zIndex: 1400, width: '100%' }}>
      <AppBar position="static" color='custom' enableColorOnDark={false}>
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
          
        <IconButton
            onClick={toggleSidebar}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2, display: {md: 'none'} }}
          >
            <MenuIcon />
          </IconButton>

          {appMode === 'dark' ? 
          <img style={{height: "30px"}} alt="Menubar Icon" src="https://res.cloudinary.com/dmzqkpypw/image/upload/v1731839117/multibroker-new_rgv6zb.png" />

          :
          
          <img style={{height: "30px"}} alt="Menubar Icon" src="https://res.cloudinary.com/dmzqkpypw/image/upload/v1731839117/multibroker-new_rgv6zb.png" />

          }

          <IconButton
          onClick={()=>{
            if (appMode === 'dark') {
            dispatch(switchMode('light'))  
            }else{
                dispatch(switchMode('dark'))
            }
        }}
            size="large"
            aria-label="app mode"
            color="inherit"
          >

            {appMode === 'dark' ? <LightModeIcon />  : <ModeNight/>}
             
          </IconButton>

            
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ zIndex: 1600}}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <ListItem disablePadding>
                      <ListItemButton>
                      <ListItemText primary={user.email} secondary={user.name} />
                      </ListItemButton>
                  </ListItem>
                </MenuItem>
                <Divider/>
                <MenuItem component={RouterLink} to="/kyc-verification" onClick={handleClose}>KYC Verification</MenuItem>
                <MenuItem component={RouterLink} to="/profile"  onClick={handleClose}>Settings</MenuItem>
                <MenuItem component={RouterLink} to="/receive"  onClick={handleClose}>Deposit</MenuItem>
                <MenuItem component={RouterLink} to="/send-coin"  onClick={handleClose}>Withdraw</MenuItem>
                <Divider/>
                <MenuItem onClick={postLogout}>{isLoggingOut? 'Logging out...': 'Logout'}</MenuItem>
                
                <MenuItem>
                <div id="trans_container" style={{height: '20px'}}>
                  <span id="google_translate_element">
                    TRANSLATING NOW
                  </span>
                </div>
                </MenuItem>

                <Divider/>
                <MenuItem sx={{display: 'flex', justifyContent: 'center'}}>

                <IconButton
                    component={RouterLink} to="/notifications"
                    size="large"
                    aria-label="show notofocations"
                    color="inherit"
                  >
                    <Badge badgeContent={notifications.length} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  </MenuItem>
              </Menu>

            
        </Toolbar>
      </AppBar>
    </Box>
  );
}